import Lottie from 'react-lottie'
import * as animationData from '../../animations/SubmittingAnimation.json';
import useResponsive from '../../app/hooks/useResponsive';
import { Backdrop, Box } from '@mui/material';
import { useStore } from '../../app/stores/store';
import { observer } from 'mobx-react-lite';

const SubmittingBackdrop = () => {
    const { surveyResponseStore: { submitting } } = useStore();
    const isBelowMd = useResponsive('down', 'md');

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Backdrop
            open={submitting}
            sx={{
                backdropFilter: 'blur(4px)',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                zIndex: theme => theme.zIndex.drawer + 1
            }}
        >
            <Box>
                <Lottie options={defaultOptions}
                    height={isBelowMd ? 200 : 350}
                    width={isBelowMd ? 200 : 350}
                />
            </Box>
        </Backdrop>
    )
}

export default observer(SubmittingBackdrop);
